html {
  background-color: black;
  color: #111;
  --card-accent: #4ea9e2;
  --admin-modal-background: #333;
  --bg0: #000;
  --bg1: #111;
  --bg2: #222;
  --bg3: #333;
  --bg4: #444;
  --bg5: #555;
  --success: #5ea050;
  --neutral: #333;
}
a {
  text-decoration: none;
  color: inherit;
}

html,
input,
textarea,
button {
  font-family: Arial, Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
}

.cards {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  align-items: start;
}

.cards.condensed {
  gap: 10px;
  width: 100%;
}

.cards.condensed .card {
  min-width: 0;
}

.card {
  flex: 0 0 auto;
  /* width: 100%; */
  width: 20em;
  /* height: 480px; */
  background: var(--card-background);
  color: var(--card-text-color, #111);
  font-family: var(--card-font-family);
  border-radius: 0.75em;
  text-align: left;
  box-sizing: border-box;
  padding: 0.5em 1.25em 1em;
  font-size: 1em;
  position: relative;
  overflow: hidden;
  /* box-shadow: var(
    --card-box-shadow,
    rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px
  ); */
}

.card.micro {
  width: 30px;
  height: 40px;
  border-radius: 0.25em;
  padding: 0;
  display: block;
  flex: 0 0 auto;
}

.card .button {
  background: var(--button-background) !important;
  color: var(--button-text-color);
  font-size: 1.125em;
  border: 0;
  font-weight: bold;
  border-radius: 0.75em;
  padding: 0.5em;
  display: block;
  box-shadow: none;
}

/* 
.card[data-card-type="prompt"]:after {
  --card-accent: #ca3125;
} */

.card.condensed {
  flex: 1 1 auto;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
}

.card * {
  font-family: var(
    --card-font-family,
    /* "SF Pro Rounded", */ "Helvetica Neue",
    "Helvetica",
    "Arial",
    sans-serif
  );
}

.card h1,
.card .headline {
  font-size: 1.5em;
  color: var(--card-headline-color);
  font-family: var(--card-headline-font);
  margin: 0.6em 0 0;
  font-weight: var(--card-headline-weight, "bold");
}
.card.condensed h1 {
  font-size: 16px;
}

.card h2,
.card .subheadline {
  font-size: 1.25em;
  color: var(--card-subheadline-color);
  margin: 0.6em 0;
}

.card h2:empty {
  display: none;
}

.card p {
  margin: 0.6em 0 0.6em;
}

.options {
  gap: 0.75em;
  margin-bottom: 0.75em;
  display: grid;
}

.option {
  border-radius: 0.75em;
  background: var(--option-background);
  /* background: rgba(255, 255, 255, 0.25); */
  border: var(--option-border);
  font-size: 1.25em;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0.75em;
}
.option:hover {
  background: rgba(255, 255, 255, 0.5);
}

.over-5-options.options {
  display: flex;
  flex-wrap: wrap;
}

input,
textarea,
.input,
.textarea {
  background: #fff;
  font-size: 1em;
  padding: 0.5em;
  border: 0.0625em solid #ddd;
  border-radius: 0.5em;
  display: block;
  color: var(--card-input-color);
  box-sizing: border-box;
}
textarea,
.textarea {
  width: 100%;
}

.card .input,
.card .textarea,
.card textarea {
  background: var(--card-input-background, "#fff");
  font-size: 1em;
  padding: 0.5em;
  border-radius: 0.5em;
  display: block;
  color: var(--card-input-color);
  width: 100%;
  box-sizing: border-box;
  border: var(--card-input-border);
}

label {
  font-size: 0.75em;
}

textarea::placeholder,
input::placeholder {
  color: #bdbbdd;
}

/* .card [type="textarea"],
.card textarea {
  min-height: 100px;
} */
.autoplay-progress {
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 12px;
  color: var(--swiper-theme-color);
}

.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: var(--swiper-theme-color);
  fill: none;
  stroke-dashoffset: calc(125.6 * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}

.timer * {
  font-size: 12px;
}
.selected {
  outline: 3px solid white;
}

.video-ui {
  display: grid;
  grid-template-columns: 1fr 460px;
  gap: 10;
}
.all-activities {
  max-height: 300px;
  overflow: auto;
  display: flex;
  gap: 10;
  flex-wrap: wrap;
}

@media (max-width: 1200px) {
  .video-ui {
    grid-template-columns: 1fr;
  }
  .all-activities {
    display: none;
  }
}

.admin-wrapper {
  display: grid;
  /* grid-template-columns: 1fr; */
  overflow: hidden;
  height: 100vh;
  height: 100dvh;
  grid-template-rows: min-content 1fr;
  width: 100vw;
  background: #222;
  color: white;
}

.admin-wrapper .response-wrapper {
  background: #333;
}

.admin-wrapper.show-live {
  grid-template-columns: 1fr 360px;
}

/* .hoverbot-wrapper > div {
  overflow: auto;
} */

.button {
  background: #4ea9e2;
  font-size: 1em;
  padding: 0.25em;
  color: white;
  border-radius: 0.25em;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.05);
  /* box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, inset white 0 0 2px; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  gap: 0.5em;
}

.button.full {
  width: 100%;
  height: 100%;
  flex: 1 1 100%;
}
.button:hover {
  /* top: -1px; */
  filter: brightness(1.1);
}

.button.large {
  font-size: 24px;
}

.button.simple {
  box-shadow: none;
  background: #626262;
}

.button.secondary {
  background: #3771b5;
}

.button.success {
  background: var(--success);
}

.button.warning {
  background: #ca3125;
}

.button.bright {
  color: white;
  background: #c4ff6b;
}
.button.bright svg {
  filter: drop-shadow(0 0 0.3em rgb(107, 64, 4));
}

.button.inactive {
  border: 1px solid var(--card-accent);
  background: transparent !important;
  /* color: black; */
  /* opacity: 0.9; */
}
.button.active {
  background: var(--card-accent);
}

.button[disabled] {
  background: #333;
}

.button[disabled]:hover {
  top: 0;
  cursor: not-allowed;
}

.button.icon {
  font-size: 1.25em;
}

.button-row {
  /* margin: 10px 0; */
  display: flex;
  gap: 4px;
  justify-content: end;
  align-items: end;
}

.row.wrap {
  flex-wrap: wrap;
}

pre {
  max-width: 100%;
  overflow: auto;
  white-space: pre-wrap;
  max-height: 200px;
}

.rejected {
  opacity: 0.5;
}

.card-wrapper {
  flex-grow: 1;
}

.cards.condensed .card-wrapper {
  display: flex;
  flex-direction: row;
  gap: 0px;
  width: 100%;
}

.inset {
  background: var(--neutral);
  /* padding: 10px; */
  box-sizing: border-box;
}

.divider {
  /* background: rgba(255, 255, 255, 0.5); */
  flex: 0 0 auto;
}

.content {
  padding: 0 1.25em;
  box-sizing: border-box;
}

/* .card-gallery {
  display: flex;
  gap: 40px;
  padding: 10px;
  background: var(--page-background);
  flex-wrap: wrap;
  flex: 1 1 auto;

  @media (max-width: 1200px) {
    justify-content: start;
    flex-direction: column;
  }
}
.card-gallery .card-wrapper {
  max-width: 320px;
} */

body,
.theme-default {
  --page-background: #f0f0f0;
  --card-background: #fff;
  --page-text: #111;
  --option-border: 1px solid #ddd;
  --button-background: #367abc;
  --button-text-color: white;
  --card-input-color: #111;
  --card-input-border: 1px solid #ddd;
  --card-headline-weight: bold;
  --card-confirmation-background: #5ea050;
  --neutral: #ddd;
}

.theme-dark {
  --page-background: #111;
  --page-text: #fff;
  --card-background: #333;
  --card-text-color: #fff;
  --card-headline-color: #fff;
  --option-border: 1px solid #transparent;
  --card-subheadline-color: #fff;
  --button-background: #111;
  --option-background: #222;
  --card-input-background: #222;
  --card-input-color: #fff;
  --card-accent: #fff;
  --neutral: #444;
}

.theme-dark .card h1,
.theme-dark .card .headline {
  font-size: 2.1em;
  letter-spacing: -0.03em;
}

.theme-dark .response {
  letter-spacing: -0.03em;
  font-size: 2.1em;
  font-weight: bold;
}

.theme-colorful {
  --page-background: #fff;
  --card-background: #367abc;
  --card-text-color: #fff;
  --neutral: rgba(255, 255, 255, 0.15);
}

.theme-colorful .card {
  --button-text-color: #fff;
  --card-accent: rgba(255, 255, 255, 0.65);
  --button-background: rgba(255, 255, 255, 0.2);
  --option-background: rgba(255, 255, 255, 0.2);
}

.theme-colorful .card[data-card-type="poll"] {
  background: #a3af43;
}
.theme-colorful .card[data-card-type="trivia"] {
  background: #d12f6c;
}

.theme-slido {
  --card-font-family: Inter, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --button-background: #7ac15c;
  --button-text-color: #fff;
  --option-background: #f8f7f9;
}

.theme-slido h1,
.theme-slido h2,
.theme-slido .option,
.theme-slido p,
.theme-slido .button {
  font-size: 14px;
}

.theme-slido .button {
  text-transform: uppercase;
}

.theme-line .card:after {
  background: var(--card-accent);
  height: 1.25em;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  content: "";
  --button-background: var(--card-accent);
  --button-text-color: white;
}

.theme-line * {
  --option-border: 0.0625em solid var(--card-accent);
  --button-background: var(--card-accent);
  --button-text-color: white;
}

.theme-line .card[data-card-type="poll"] {
  --card-accent: #d12f6c;
}

.theme-line .card[data-card-type="prompt"] {
  --card-accent: #3771b5;
}

.theme-pixel {
  --page-background: url(https://media1.giphy.com/media/4heseFMvObk9q/giphy.gif?cid=ecf05e47r5ltjce9x3e8drmknssj994xtitkjy0g9dvmxc3k&ep=v1_gifs_related&rid=giphy.gif&ct=g);
  --card-font-family: "Victor Mono", monospace;
  --card-background: rgb(0, 0, 0, 0.75);
  --card-text-color: lime;
  font-weight: 900;
}

.theme-celtics {
  --card-background: #028348;
  --card-text-color: #fff;
  --page-background: url(https://media.nbcboston.com/2022/06/16762834481-1080pnbcstations.jpg?quality=85&strip=all);
  --page-text: #fff;
  --button-text-color: #028348;
  --button-background: #fff;
  --option-border: 2px solid #fff;
  --card-accent: #000;
}

.theme-celtics .card {
  --card-accent: #fff !important;
}

.theme-loud {
  --card-background: #ff04a1;
  --card-text-color: yellow;
  --card-accent: yellow;
  --button-background: #00c2ff;
  --option-background: #00c2ff;
  --card-headline-font: "Borel", serif;
}

.theme-fashion {
  --card-headline-font: "Abril Fatface", serif;
  --card-background: #fff;
  --page-background: #fff;
  --card-text-color: #000;
  --card-font-family: "Libre Baskerville", serif;
  --card-accent: #000;
}

.theme-fashion h1 {
  font-size: 3em;
  font-weight: 400;
}

.button.transparent {
  background: transparent !important;
  color: inherit;
}

.dismiss-button-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
}

.countdown-container {
  /* width: 100%; */
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 12px;
  /* background-color: #e0e0de; */
  overflow: hidden;
}

.countdown-progress {
  height: 100%;
  width: 100%;
  background-color: var(--card-accent);
  animation-name: countdown;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes countdown {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

.portal {
  z-index: 10000;
  position: relative;
  max-height: 100vh;
}

.portal .close {
  position: absolute;
  top: -30px;
  right: 0;
  z-index: 10001;
}

.backdrop {
  z-index: 9999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: rgba(21, 15, 30, 0.569);
}

.card-editor {
  position: relative;
  background-color: var(--bg3);
  border-radius: 1.75em;
  overflow: hidden;
  display: flex;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
  min-height: min(600px, calc(100vh - 40px));
  max-height: calc(100vh - 40px);
  min-width: 80vw;
  width: 100%;
  max-width: calc(100vw - 40px);
}

@media (max-width: 800px) {
  .card-editor {
    flex-direction: column;
    max-width: calc(100vw - 20px);
  }

  .card-settings {
    min-height: 50vh;
  }

  .card-canvas {
    min-height: 200px;
  }
}

.card-canvas {
  background: var(--bg1);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  overflow: hidden;
  flex: 1 1 auto;
}

/* .card-canvas .card {
  width: 320px;
} */

.card-canvas > .padding {
  display: flex;
  /* width: 320px; */
  align-items: center;
  gap: 2em;
  padding: 0.75em 4em 0.75em;
  flex-direction: column;
  overflow: auto;
  flex: 1 1 auto;
  width: 100%;
}
@media (max-width: 800px) {
  .card-canvas > .padding {
    /* display: block; */
  }
}

.card-canvas > .padding > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;
}

.preview-output .output-screen {
  height: auto;
  aspect-ratio: 16 / 9;
  width: 100em;
  border: 1px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.2em !important;
}
/* .preview-output .output-screen .card {
  font-size: 12px;
} */

.card-editor .output-screen {
  width: 100em;
  font-size: 0.4em !important;
}

.card-editor .card-settings {
  width: 20em;
  height: 100%;
  /* flex: 0 0 auto; */
  width: 340px;
  /* overflow: auto; */
  /* margin: 20px; */
  display: flex;
  flex-direction: column;
}

.card-settings .padding {
  padding: 1.25em;
  overflow: auto;
  height: 100%;
}

label {
  font-size: 1em;
}
.field {
  display: flex;
  justify-content: space-between;
  padding: 0.25em;
  border-radius: 1.25em;
}
.field.multirow {
  flex-direction: column;
  align-items: flex-start;
}
.field-group {
  background: rgba(0, 0, 0, 0.05);
}

.editable {
  font-size: inherit !important;
  font-family: inherit !important;
  padding: inherit !important;
  margin: inherit !important;
  border: inherit !important;
  font-weight: inherit;
  outline: inherit;
  border-radius: inherit;
  box-shadow: inherit;
  resize: none;
  display: block !important;
  box-sizing: border-box;
  background: inherit !important;
  color: inherit !important;
  text-align: inherit !important;
  /* display: inherit !important; */
}

.editable:not(.fake-input) {
  width: 100% !important;
}

.editable:hover,
.editable:focus {
  outline: 0.0625em dashed gray;
}

.form-flex {
  display: flex;
  flex-direction: column;
  gap: 0.75em;
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.option-text {
  width: 100%;
}

.toggle input[type="checkbox"]:checked {
  background: green;
}
.toggle input[type="checkbox"]:checked + span {
  left: 1.5em;
}

.canvas-mode-controls {
  justify-content: center !important;
  gap: 0.75em;
}

.form-controls {
  display: flex;
  justify-content: flex-end;
}

.noninteractive * {
  pointer-events: none;
}

.clickable {
  cursor: pointer;
}

.card.clickable:hover {
  outline: 4px solid var(--card-accent);
}

.toolbar {
  /* height: 3em; */
  width: 100%;
  /* background: rgba(0, 0, 0, 0.15); */
  display: flex;
  align-items: center;
  padding: 0 1.25em;
  box-sizing: border-box;
  justify-content: space-between;
  /* backdrop-filter: blur(10px); */
  gap: 8px;
  flex: 0 0 auto;
  height: fit-content;
}

.toolbar.sticky {
  position: sticky;
  top: 0;
  z-index: 100000;
}
.toolbar.centered {
  justify-content: center;
}

.toolbar-start {
  display: flex;
  align-items: center;
  gap: 0.75em;
  margin-right: auto;
}

.form-row {
  margin-bottom: 0.6em;
}

.editable-controls {
  display: flex;
  gap: 0.5em;
}

.tab {
  background: transparent;
  border: 0;
  border-bottom: 0.2em solid transparent;
  /* height: 100%; */
  box-sizing: border-box;
  padding-top: 0.5em;
  cursor: pointer;
  padding: 0.5em;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  color: inherit;
  /* border-radius: 0 0 0.5em 0.5em; */
  border-radius: 0;
  color: #ddd;
}

.tab.active,
.tab:hover {
  /* height: 100%; */
  border-bottom: 0.2em solid var(--card-accent);
  color: white;
}
.tab.active {
  /* font-weight: bold; */
}
.tab.active svg {
  color: var(--card-accent);
}

.tab-bar {
  display: flex;
}

.card-deck-previews {
  display: flex;
  flex-direction: column;
  margin: 1.25em;
  gap: 1.75em;
}

.card-deck-preview {
  border-radius: 1.25em;
  background: var(--page-background);
  color: var(--page-text);
  overflow: hidden;
  display: grid;
  grid-template-columns: 200px 1fr;
  /* height: 120px; */
  padding: 1.25em 1.25em 0;
  flex: 0 0 auto;
  box-shadow: var(
    --card-box-shadow,
    rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px
  );
}

.card-deck-preview .heading {
  font-size: 1.5em;
}

.card-deck-preview .cards {
  transform: scale(0.8);
  margin-top: -8px;
  margin-bottom: -120px;
  transform-origin: top left;
  flex-wrap: nowrap;
  margin-left: 20px;
}

.card-deck-preview .cards * {
  flex-shrink: 0;
}

/* .card-deck-preview .card {
  transform: scale(0.8);
}
.card-deck-preview .cards > *:nth-child(1) {
  z-index: 1;
  transform: scale(0.8) translate(0, -10px);
}
.card-deck-preview .cards > *:nth-child(2) {
  transform: scale(0.8) translate(-300px, 40px);
  z-index: 2;
}
.card-deck-preview .cards > *:nth-child(3) {
  transform: scale(0.8) translate(-600px, 80px);
  z-index: 3;
}
.card-deck-preview .cards > *:hover {
  z-index: 10;
} */

.viewer {
  height: 100vh;
  overflow: auto;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  background: var(--page-background);
  color: var(--page-text);
}

.viewer .card,
.preview-output .card {
  width: calc(100% - 0.5em);
}

.viewer header {
  /* background: var(--card-background); */
  color: var(--page-text);
  display: flex;
  height: 3em;
  justify-content: start;
  align-items: center;
  padding: 0 0.8em;

  border-radius: 0.75em 0.75em 0 0;
  /* width: calc(100% - 8px); */
  box-sizing: border-box;
  justify-content: space-between;
  align-self: flex-end;
}

.viewer header button {
  background: transparent;
  color: inherit;
  border: 0;
}

.theme-picker {
  overflow: auto;
}

.theme-picker .cards {
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.theme-picker .card {
  flex: 0 0 auto;
  width: 20em;
}

.cards.enable-hover .card-wrapper {
  position: relative;
}
.cards.enable-hover .card-wrapper .hover-content {
  display: none;
  position: absolute;
  right: 100%;
  top: 0;
}

.cards.enable-hover .card-wrapper:hover .hover-content {
  display: block;
}

.card-type-picker {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 20px;
}

.card-type-list {
  width: 300px;
  gap: 20px;
  display: flex;
  flex-direction: column;
}
.card-type-tile {
  background: white;
  padding: 10px;
  border-radius: 10px;
}

.card-type-tile.active {
  outline: 2px solid black;
}

.suggestions-page-wrapper {
  display: grid;
  grid-template-columns: 300px 1fr;
  margin: 10px;
}

@media (max-width: 800px) {
  .suggestions-page-wrapper {
    grid-template-columns: 1fr;
  }
}

.onboarding-wrapper {
  position: relative;
  background-color: var(--admin-modal-background);
  color: white;
  border-radius: 30px;
  overflow: hidden;
  margin: 20px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.5);
}

.subtle {
  color: #333;
}

.playground {
  background: #999;
}

.playground .button {
  margin: 4px;
}

.playground .card-shuffle,
.playground .card-stack,
.playground .card-swipe {
  width: 360px;
  height: 640px;
  overflow: hidden;
  background: #888;
  border: 30px solid black;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.playground .card-shuffle {
  .swiper {
    width: 360px;
    height: 640px;
  }

  .swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    min-height: 500px;
  }
}

.badge {
  background: var(--card-accent, red);
  padding: 4px 8px;
  font-weight: bold;
  color: white;
  border-radius: 10px;
  margin: 4px;
}

.inline-block {
  display: inline-block;
}

.no-hover:hover {
  top: unset;
  margin: unset;
}

.big-icon-wrapper {
  display: flex;
  justify-content: center;
  /* color: var(--card-accent); */
}

.hint {
  font-size: 0.7em;
}

.big-list-entry {
  display: flex;
  font-weight: bold;
  padding: 0.125em;
  min-height: 1em;
  align-items: baseline;
  width: 100%;
}
.big-list-entry .answer {
  border-bottom: 0.0625em solid var(--neutral);
  display: bloweck;
  width: 100%;
}

.card:not([data-editable]).big-list-entry.unanswered .answer {
  color: transparent;
}

.output-screen .big-list-wrapper .prompt-response {
  font-size: 0.5em;
}

.big-list-entry .hint {
  margin-right: 0.75em;
}

.card:not([data-editable]) .big-list-entry.answered .hint {
  text-decoration: line-through;
}

.viewer-entry-form {
  /* position: fixed;
  bottom: 0;
  left: 0;
  right: 0; */
}

.viewer-logged-in {
  color: var(--page-text);
  display: flex;
  gap: 0.75em;
}

.empty-state {
  color: var(--page-text);
  text-align: center;
  width: 100%;
  left: 0;
  position: fixed;
  z-index: 0;
}

.card[data-card-type="confirmation"] {
  background: var(--card-confirmation-background) !important;
  color: white;
  text-align: center;
  padding: 2em 1.5em;

  > * {
    width: 100%;
  }
}

.viewer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.viewer .card-list {
  max-width: 25em;
  width: 100%;
  justify-content: start;
  align-items: stretch;
  justify-content: start;
  flex-direction: column;
  display: flex;
  height: 100%;
  gap: 2em;
  padding: 1.25em 0;
  box-sizing: border-box;
}

.join-cta {
  font-size: 44px;
  display: flex;
  width: auto;
  gap: 20px;
  justify-content: center;
  align-items: flex-end;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 20px;
  white-space: nowrap;
  z-index: 10000;
}

.join-cta svg {
  border: 20px solid white;
  border-radius: 20px;
}

.output-card-wrapper {
  width: calc(100% - 20em);
  max-height: 100%;
}

.prompt-output .prompt-response .displayName {
  font-size: 1em;
  font-weight: bold;
}
.prompt-output .prompt-response .response {
  font-size: 2em;
}

.poll-output {
  display: flex;
  flex-direction: column;
  gap: 0.75em;
}

.poll-result.option {
  display: flex;
  flex-direction: column;
}

.horizontal-bars {
  display: flex;
  flex-direction: column;
  gap: 0.75em;
}

.horizontal-bars .option {
  display: grid;
  /* flex-direction: row; */
  grid-template-columns: 1fr min-content min-content;
  gap: 0.5em;
}

.poll-output.horizontal-bars .option .bar-wrapper {
  grid-column: span 3;
  grid-row: 2;
}
.list .option {
  grid-column: span 3;
  grid-template-columns: 1fr min-content min-content;
  gap: 0.5em;
}

.poll-output.list .option {
  display: Grid;
}
.poll-output.list .option .bar-wrapper {
  display: none;
}

.bar-wrapper {
  overflow: hidden;
  width: 100%;
  height: 1.5em;
  background: var(--neutral);
  border-radius: 0.75em;
}
.bar-amount {
  height: 100%;
  background: var(--card-accent);
}

.admin-responses {
  padding: 0.75em;
  gap: 0.75em;
  display: flex;
  flex-direction: column;
}

.response-wrapper {
  padding: 0.5em;
  border-radius: 0.25em;
  background: var(--neutral);
  display: flex;
  flex-direction: column;
}

.admin-responses .response-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: white;
  color: black;
}

.admin-responses .response {
  grid-column: span 2;
  font-size: 1.1em;
  font-weight: 500;
}

.admin-cards .card-wrapper .card {
  max-height: 10em;
  /* overflow: hidden; */
  /* border-end-end-radius: 0;
  border-end-start-radius: 0; */
  box-shadow: 1px 1px 0 #ddd, -1px -1px 0 #ddd, 0 0 3px #aaa;
}

.dogear {
  position: absolute;
  top: 0;
  width: 30px;
  height: 30px;
  right: 0;
  z-index: 1000;
  overflow: hidden;
  color: transparent;
}

.dogear:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(
    to top right,
    transparent 0%,
    transparent 50%,
    currentColor 50%,
    currentColor 100%
  );
  width: 40px;
  height: 40px;
  /* transform: rotate(180deg); */
}

.approved .dogear {
  color: #5ea050;
}
.rejected .dogear {
  color: red;
}

.dogear button {
  opacity: 0;
}

@keyframes pulse {
  0% {
    outline-color: #4ea9e2;
  }
  50% {
    outline-color: #e77619;
  }
  75% {
    outline-color: #a05085;
  }
  100% {
    outline-color: #4ea9e2;
  }
}

.timeline .card.selected {
  outline: 5px dashed #a05085;
}
.timeline .card.active {
  animation: pulse 3s infinite;
  outline: 5px solid #4ea9e2;
}

/* .admin-cards .active.card-wrapper {
  position: relative;
  overflow: visible;
}
.admin-cards .active.card-wrapper:before {
  content: "";
  background: blue;
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -4px;
  z-index: 0;
} */

.timeline {
  display: flex;
  flex-wrap: nowrap;
  /* overflow: hidden; */
  position: relative;
  /* gap: 16px; */
  background: #060606;
  width: 100%;
}

.timeline .cards {
  padding: 1em;
  flex-wrap: nowrap;
  gap: 1em;
}

/* .timeline .active {
  flex: 0 0 auto;
  width: min-content;
  background: #000;
  padding: 10px;
} */

.timeline .queued,
.timeline .suggestions {
  flex: 1 1 auto;
  flex-wrap: nowrap;
  display: flex;
  overflow: auto;
  padding: 0 0.75em 0.75em;
  padding-bottom: 0.75em;
}

.timeline .queued .entries,
.timeline .suggestions .entries {
  margin: 0.75em 0 0;
}
.timeline .queued .add-new {
  margin: 0.75em 0 0 0.75em;
}

.timeline .card *:not(h1) {
  display: none;
}

.timeline .card-wrapper {
  /* width: 130px; */
}

.timeline .card {
  width: 10.5em;
  min-width: auto;
  height: 5.4em;
  padding: 0em 0.75em 0.25em;
}

.timeline .card h1 {
  font-size: 0.875em;
}

.button-wrapper-responses {
  position: relative;
}

.button-wrapper-responses .total-responses {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 4px;
  display: flex;
  z-index: 2;
  justify-content: center;
  align-items: center;
  color: #538548;
  font-weight: bold;
  font-size: 12px;
}

.timeline .entries {
  display: flex;
  gap: 0.75em;
}

.timeline > .controls {
  display: flex;
  gap: 0.25em;
  margin: 0 0.25em;
}

.drag-overlay {
  z-index: 99999999;
}

.sortable-item {
  position: relative;
}

.sortable-item .handle {
  position: absolute;
  top: 0;
  right: 0;
  /* background: #eee; */
  cursor: grab;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #777;

  /* color: transparent;
  left: 0;
  bottom: 24px;
  width: auto;
  height: auto;
  z-index: 10000;
  &:hover {
    color: #777;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0.5em;
  } */
}

.timeline .card {
  cursor: pointer;
}

.timeline .entry .controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  border-radius: 0 0 0.75em 0.75em;
  display: flex;
}

.timeline .entry .controls > * {
  flex: 1 1 auto;
  width: 100%;
}

.timeline .entry .controls > .button {
  border-radius: 0;
}

@media (max-width: 800px) {
  .timeline {
    flex-direction: column;
    width: 100vw;
  }
}

.card-gallery-card-wrapper {
  width: 20em;
}

.card-gallery.grid .card-gallery-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card-gallery.short-grid .card-gallery-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.card-gallery.short-grid .card-gallery-cards .card-gallery-card-wrapper .card {
  max-height: 100px;
  overflow: hidden;
}

.card-gallery.medium-grid .card-gallery-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.card-gallery.medium-grid .card-gallery-cards .card-gallery-card-wrapper .card {
  max-height: 200px;
  overflow: hidden;
}

.card-gallery.condensed .card-gallery-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.view-mode-picker {
  display: flex;
  gap: 8px;
  position: sticky;
  top: 10px;
  margin: 12px;
  flex-wrap: wrap;
  overflow: auto;
  max-width: 100%;
}

.card-gallery.list .card-gallery-cards,
.card-gallery.list-condensed .card-gallery-cards {
  display: flex;
  flex-direction: column;
  max-width: 320px;
}

.viewer-preview {
  margin: 10px;
}

.output-preview.output-screen {
  height: 100%;
  /* font-size: 3px; */
  overflow: hidden;
}
.output-preview.output-screen . {
  font-size: 10px;
}

.output-screen {
  height: 100vh;

  background: var(--page-background);
  color: var(--page-text);
  font-size: min(0.8cqw, 1cqh);
}

.outline {
  outline: 1px solid #ccc;
}

.output-screen .card {
  width: 100%;
  padding: 0 1.6em 1.6em;
  box-shadow: 20px 20px 50px --var(--card-accent);
  font-size: 2.5em;
}

.output-cards {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

/* .output-screen .card h1,
.output-screen .card .headline {
  font-size: 3em;
} */
/*  */

.fake-link {
  text-decoration: underline;
}

.output-screen .big-list-wrapper {
  display: grid;
  gap: 1em;
  grid-template-columns: 1fr 16em;
}

.event-types-picker {
  width: 400px;
  /* overflow: auto; */
  padding: 20px;
  height: 500px;
}

.event-types-picker .card {
  margin: 5px;
  width: 250px;
}

.button-set {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* .swiper-wrapper {
  width: 400px;
} */

.viewer [data-card-type="break"],
.card-canvas.preview [data-card-type="break"] {
  display: none;
}

.timeline [data-card-type="break"] {
  background: none;
  width: 0.25em;
}

.timeline .empty-card {
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid white;
  color: white;
}

.suggestions {
  display: flex;
  flex-direction: column;
  gap: 1.25em;
  /* margin: 0 20px; */
}

.suggestion-block {
  border: 1px #ccc solid;
  padding: 1.25em;
  border-radius: 1.25em;
  background: var(--page-background);
  color: var(--page-text);
}

.center-column {
  /* flex: 1 1 auto; */
  display: grid;
  grid-template-rows: 1fr min-content;
  overflow: hidden;
}

.center-column .outlet {
  /* height: 100%; */
  grid-row: 1;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
}

.center-column .timeline-wrapper {
  grid-row: 2;
  width: 100vw;
}

.preview-wrapper {
  position: relative;
  width: 100%;
  max-width: 100%;
  /* border: 1px solid black; */
  overflow: hidden;
  height: auto;
  /* aspect-ratio: 16 / 9; */
  /* max-height: calc(100vh - 30em); */
  grid-column: 1;
  grid-row: 1;
}
.preview-wrapper-toggle {
  display: none;
  font-size: 16px;

  .preview-full & {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    z-index: 1000;
  }
}
@media (max-width: 800px) {
  .preview-wrapper-toggle {
    display: block;
  }

  .preview-full .preview-wrapper-toggle div {
    display: none;
  }

  .preview-small .button {
    width: 100%;
  }
}

.preview-wrapper .output-screen,
.preview-wrapper .viewer {
  height: 100%;
  width: 100%;
  position: relative;
}
.preview-wrapper .output-wrapper {
  /* position: absolute; */
  /* width: 100%; */
  /* font-size: 8px; */
  /* border: 1px solid black; */
  position: relative;
  overflow: hidden;
  aspect-ratio: 16 / 9;
  /* height: 100%; */
  max-height: 100%;
  max-width: 100%;
}
.preview-wrapper .viewer-wrapper {
  z-index: 1000;
  position: absolute;
  aspect-ratio: 9 / 15;
  right: 0.9cqw;
  border: 0.3em solid rgb(42, 33, 57);
  /* border-top-width: 1em; */
  border-bottom-width: 0;
  background: rgb(42, 33, 57);
  border-radius: 20px;
  padding: 10px;
  width: 20em;
  font-size: 0.9cqw;
  overflow: auto;
  bottom: -20em;
  transition: all ease-in-out 0.22s;
}
.preview-wrapper .viewer-wrapper:after {
  content: "";
  background: rgb(42, 33, 57);
  width: 30%;
  left: 35%;
  position: absolute;
  top: 0;
  z-index: 10000;
  border-radius: 1em;
  height: 1.5em;
}
.preview-wrapper .viewer-wrapper .viewer {
  /* font-size: 0.6em; */
}
.preview-wrapper .viewer-wrapper:hover {
  bottom: -1em;
}
@media (max-width: 800px) {
  .preview-wrapper {
    /* aspect-ratio: 16 / 9; */
    height: auto;
    width: 100vw;
    flex: 0 0 auto;
  }
  .preview-wrapper.preview-small {
    min-height: 1em;
    aspect-ratio: auto;
  }
  .preview-wrapper .output-wrapper {
    font-size: 0.8vw;
    width: 100vw;
    position: relative;
    height: auto;
    box-sizing: border-box;
    /* max-height: 100px; */
    aspect-ratio: 15 / 9;
  }
  .preview-wrapper.preview-small .output-wrapper {
    height: 80px;
    font-size: 0.01em;
    width: auto;
    display: none;
  }

  .preview-wrapper .viewer-wrapper {
    max-height: 100px;
    position: relative;
    font-size: 0.5vw;
    width: 100%;
    box-sizing: border-box;
    display: none;
  }
}

.admin-live-wrapper {
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-template-rows: fit-content(60vh) auto 1fr;
  overflow: hidden;
  height: 100%;
}

.admin-card-preview-controls {
  grid-column: 1;
  grid-row: 2;
  display: flex;
  justify-content: center;
  height: auto;
  flex: 0 0 auto;
  background-color: var(--bg1);
  font-size: 0.9em;
}

@media (max-width: 800px) {
  .admin-card-preview-controls {
    order: 5;
  }
  .admin-live-wrapper {
    display: flex;
    flex-direction: column;
    /* grid-template-columns: 1fr; */
  }
}

.admin-responses-wrapper {
  overflow: auto;
  max-height: 100%;
  /* height: 100%; */
  grid-row: span 3;
  flex: 1 1 auto;
  margin-bottom: 0.4em;
}

.preview-wrapper .viewer {
  padding: 0 10px;
  box-sizing: border-box;
}

.admin-card-controls {
  grid-column: 1;
  grid-row: 3;
  flex: 1 1 auto;
  overflow: auto;
}

.tile {
  display: grid;
  grid-template-rows: 1fr max-content;
  justify-items: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  font-size: 1.1em;
  gap: 0.3em;
}

.tile div {
  height: 100%;
  font-size: 0.8em;
}

.tiles {
  display: grid;
  grid-template-columns: repeat(auto-fill, 120px);
  gap: 10px;
}

@media (max-width: 800px) {
  .tiles {
    display: flex;
    flex-wrap: wrap;
  }
  .tiles.scroll {
    overflow: auto;
    flex-wrap: nowrap;
  }
  .tile {
    grid-template-columns: max-content 1fr;
    grid-template-rows: auto;
    text-align: left;
    gap: 0.5em;
  }
  .tile span {
    width: 100%;
  }
  .tile svg {
    font-size: 1em;
  }
}

@keyframes pulseBG {
  0% {
    background: #4ea9e2;
  }
  50% {
    background: #e77619;
  }
  75% {
    background: #a05085;
  }
  100% {
    background: #4ea9e2;
  }
}

.next-card {
  display: flex;
  gap: 0.3em;
  animation: pulseBG 3s infinite;
}

@media (max-width: 800px) {
  .button.next-card {
    font-size: 1em;
  }
}

.tile-grid {
  display: grid;
  gap: 0.2em;
  grid-template-columns: repeat(auto-fill, 8em);
}

.suggested-card .card {
  /* width: em; */
  font-size: 0.75em;
}

.suggested-card {
  width: 16em;
}
.new-card-modal {
  width: 46em;
  max-width: calc(100vw - 2em);
  box-sizing: border-box;
  background: var(--admin-modal-background);
  color: white;
  padding: 2em;
  border-radius: 1em;
}

.suggested-cards .card-stack {
  /* height: 10em; */
  /* overflow: hidden; */
}
.suggested-cards .card-stack .swiper {
  /* padding-bottom: 4em; */
}

.admin-panels,
.panel {
  display: flex;
  flex-direction: column;
  gap: 0.7em;
}
.toolbar .tab span {
  margin-left: 0.5em;
}

.admin-panels .panel {
  background: var(--bg3);
  padding: 1em;
  border-radius: 0.7em;
}

.admin-mobile-live-tabs {
  display: none;
}
@media (max-width: 800px) {
  .admin-mobile-live-tabs {
    display: flex;
    order: 4;
    /* grid: 1 1; */
  }
  .admin-mobile-live-tabs.tabs {
    background-color: var(--bg1);
  }
  .admin-mobile-live-tabs .tab {
    width: 100%;
  }
  .admin-mobile-live-tabs .tab.active {
    width: 100%;
    background-color: var(--bg2);
  }

  .toolbar .session-name {
    display: none;
  }
  .toolbar .tab span {
    display: none;
  }
  .admin-panels {
    padding: 0.5em;
    overflow: auto;
    margin-bottom: 0.3em;
  }

  [data-mobile-nav="settings"]
    [data-mobile-content]:not([data-mobile-content="settings"]) {
    display: none !important;
  }
  [data-mobile-nav="responses"]
    [data-mobile-content]:not([data-mobile-content="responses"]) {
    display: none !important;
  }
}

.timeline-button,
.suggestion-button {
  display: flex;
  flex: 0 0 auto;
  width: 3em;
  position: relative;

  svg {
    font-size: 4em;
  }
}

@media (min-width: 800px) {
  .timeline-button,
  .suggestion-button,
  .suggestions-wrapper {
    display: none;
  }
}
.suggestion-button .badge {
  position: absolute;
  font-size: 0.8em;
  top: -1em;
  right: -1em;
}

.banner.warning {
  /* padding: 0.5em; */
  margin: 0.5em;
  border-radius: 1em;
  font-size: 1em;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.pulse-bg {
  animation: pulseBG 2s infinite;
}

.bg0 {
  background: var(--bg0);
}
.bg1 {
  background: var(--bg1);
}
.bg2 {
  background: var(--bg2);
}
.bg3 {
  background: var(--bg3);
}

.padded {
  padding: 0.3em;
}

.headline-toggle {
  border: 0;
  justify-content: left;
}

[data-disclosure][aria-expanded="false"] {
  .icon-open {
    display: none;
  }
  .icon-closed {
    display: block;
  }
}
[data-disclosure][aria-expanded="true"] {
  .icon-open {
    display: block;
  }
  .icon-closed {
    display: none;
  }
}

.edit-options {
  display: flex;
  flex-direction: column;
}
.edit-option {
  display: flex;
}

.warning-bg {
  background: #ca3125;
}

.over-3-options.options {
  display: grid;
}

.options.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10em, 1fr));
}

.correct {
  background: #538548;
}

.admin-participation {
  display: flex;
  gap: 0.1em;
  overflow: auto;
}

.admin-participation .participant {
  width: 1.8em;
  aspect-ratio: 1;
  border: 1px solid #333;
  border-radius: 1em;
  display: flex;
  flex: 0 0 auto;
  overflow: hidden;

  align-items: center;
  justify-content: center;
}

.admin-participation .participant.participated {
  background: var(--success);
}

.participation-stats {
  display: flex;
  flex-direction: column;
  font-size: 0.8em;
  justify-content: center;
  align-items: center;
  gap: 0.2em;
}

.lobby-output {
  gap: 0.5em;
  display: grid;
}
.lobby-user {
  display: flex;
  gap: 0.2em;
}

.detailed-list {
  display: flex;
  flex-direction: column;
}
.detailed-list .row {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
}

.detailed-list .output {
  aspect-ratio: 16 / 9;
  width: 100em;
  border: 1px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.4em !important;
}

.detailed-list .card-gallery-card-wrapper {
  width: 100%;
}

.detailed-list .viewer-preview .card {
  font-size: 0.7em;
}
